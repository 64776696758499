<template>
  <div class="lightbox-wrapper">
    <div
      class="lightbox mx-auto bg-white drop-shadow relative col-12 border-box"
    >
      <div class="close-btn white" @click="closeLightbox()">
        <img style="width: 1.5rem" src="./assets/cross_icon.svg" />
      </div>
      <div class="p3 mb2" style="background-color: darkred">
        <h2 class="white">Termin: {{ appointment.bookingcode }}</h2>
      </div>
      <div v-show="!postSuccess" class="pb4">
        <user-info :aD="appointment" />
        <div class="p3">
          <hr class="mb3" />
          <checkboxes :aD="appointment" />
          <h3 class="mt3 sm-mb2 mb4">
            Digitale Unterschrift
            <span v-if="!isOver18">eines Erziehungsberechtigten</span>
          </h3>
          <!-- <div id="sketch" class="mb2" style="width: 100%; height: 500px; border:1px solid black">
                            <canvas id="can"></canvas>
                        </div>
                        <button id="reset-btn" class="btn std white mb3" :class="{inactive: !canvasTouched}" @click="clearArea()">Signatur zurücksetzen</button>  -->
          <sketchpad :canvasHeight="250" />
          <submit-button
            name="Abschicken"
            :validInput="isValid && checkBoxesValid"
            event_name="pre_pre_submit_signature"
            :btnAnimation="false"
          />
        </div>
      </div>
      <div v-show="postSuccess" class="pb4">
        <div class="p3 py4">
          <h2 class="mb3 center">Erfassungsbogen erfolgreich ausgefüllt!</h2>
          <div class="flex justify-center">
            <button class="btn std white mb3" @click="closeLightbox()">
              Zurück zur Übersicht
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "@/event-bus.js";
import SubmitButton from "./SubmitButton.vue";
import Sketchpad from "./Sketchpad.vue";
import CheckBoxes from "./CheckBoxes.vue";
import UserInfo from "./UserInfo.vue";
import axios from "axios";

export default {
  name: "Lightbox",
  props: {
    appointment: Object,
    APIENDPOINT: String,
  },
  data() {
    return {
      isValid: false,
      checkBoxesValid: false,
      signature: "",
      postSuccess: false,
    };
  },
  created() {
    let that = this;
    EventBus.$on("broadcast_sketchState", (payload) => {
      that.isValid = payload.sketchState;
    });
    EventBus.$on("send_sketch_data", (payload) => {
      that.signature = payload.sketch_data;
    });
    EventBus.$on("pre_pre_submit_signature", () => {
      EventBus.$emit("request_sketch");
    });
    EventBus.$on("sendBoxData", (payload) => {
      this.checkBoxesValid = payload.checkBoxesValid;
    });
    EventBus.$on("pre_submit_signature", () => {
      console.log("received pre_submit_signature");
      var createURL =
        this.APIENDPOINT +
        "/onboarding/appointments/signage/" +
        that.appointment.bookingcode +
        "/" +
        that.appointment.crypt +
        "/do";
      EventBus.$emit("show_loading_lightbox");
      axios
        .post(createURL, {
          sign_in_base64: that.signature,
        }, {timeout: 30000})
        .then(function (response) {
          EventBus.$emit("close_loading_lightbox");
          console.log(response);
          that.postSuccess = true;
        })
        .catch(function (error) {
          console.log("catch Function of AJAX-Called... error()");
          console.log(error);
          that.apiResponse = error;
          EventBus.$emit('show_error_lightbox');
          EventBus.$emit('close_loading_lightbox');
        });
    });
  },
  beforeDestroy() {
    //do something before destroying vue instance
    EventBus.$off("broadcast_sketchState");
    EventBus.$off("send_sketch_data");
    EventBus.$off("pre_pre_submit_signature");
    EventBus.$off("pre_submit_signature");
    EventBus.$off("sendBoxData");
  },
  watch: {
    signature: function (newValue, oldValue) {
      if (newValue != oldValue && newValue != "") {
        EventBus.$emit("pre_submit_signature");
      }
    },
  },
  computed: {
    isOver18: function () {
      let bd = new Date(this.appointment.customer_birthday).getTime();
      var today = new Date();
      var myToday = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      console.log("bd:" + bd);
      console.log("today:" + myToday);
      console.log("calc:" + ((myToday.valueOf() - bd) / 1000 + 1));
      if ((myToday.valueOf() - bd) / 1000 + 1 > 568024668) {
        // Number for 18 years in seconds
        return true;
      } else {
        return false;
      }
    },
  },
  components: {
    "submit-button": SubmitButton,
    sketchpad: Sketchpad,
    checkboxes: CheckBoxes,
    "user-info": UserInfo,
  },
  methods: {
    closeLightbox() {
      EventBus.$emit("dataRefresh");
      EventBus.$emit("close_lightbox");
    },
  },
};
</script>

<style scoped>
.lightbox-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  min-height: 100vh;
  width: 100%;
}
.close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  transform: rotate(45deg);
  cursor: pointer;
}
</style>