<template>
    <div class="justify-center col-12 mx-auto">
            <div class="max-width-3 mx-auto p3 mt3 border-box drop-shadow">
              <h1 class="mb2">Backend Login</h1>
              <p v-if="not_valid_login" class="error py2" style="color: red">{{errorMsg}}</p>
              <p class="p-medium light mb0-5">E-Mail-Adresse: *</p>
              <input class="forminput" v-model="backend_login_mail" placeholder="" type="email">
              <div class="mb1"></div>
              <p class="p-medium light mb0-5">Passwort *</p>
              <input class="forminput" v-model="backend_login_password" placeholder="" type="password">
              <div class="mb2"></div>
              <submit-button name="Login" :validInput="isValid" event_name="validate_backend_login" :btnAnimation="false"/>
            </div>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";
import SubmitButton from './SubmitButton.vue';
import axios from 'axios';

export default {
  name: 'Login',
  props: {
    name: String,
    event_name: String,
    button_style: String,
    appointment: Boolean,
    onBoardingAppointmentTestcenter: Number,
    APIENDPOINT: String
  },
  data() {
    return {
      backend_login_mail: '',
      backend_login_password: '',
      not_valid_login: false,
      isValid: false,

    }
  },
  created() {
    EventBus.$on('pre_submit_step_app100', () => {
      console.log(this.date);
      if(this.date != "") {
        this.notfilled = false;
          EventBus.$emit('submit_step_app100', {
           "appointment_date": this.date
          });
      } else {
        // TODO: Alertbox nothing inserted!
        this.notfilled = true;
      }
    });

    EventBus.$on('validate_backend_login', () => {
      EventBus.$emit('close_error_lighhtbox');
      this.not_valid_login = false;
      var createURL = this.APIENDPOINT + '/signage/get-unsigned-for-tablets'
      var that = this
      axios.post(createURL, {
        backend_login_email: that.backend_login_mail,
        backend_login_password: that.backend_login_password
      }, {timeout: 30000}).then(function (response) {
        if(response.data.login_proof == true){
          console.log('SUCCESS')
          EventBus.$emit('login_success', {
            "user_id": response.data.login.userid,
            "user_backend_login_mail": that.backend_login_mail,
            "user_backend_login_password": that.backend_login_password,
            "appointments_to_be_signed": response.data.appointment_to_be_signed
          })
          localStorage.userName = that.backend_login_mail
          localStorage.userPw = that.backend_login_password
          that.backend_login_mail = ''
          that.backend_login_password = ''
          that.not_valid_login = false
          console.log('set localstorage')
        }
        if(response.data.login_proof == false){
          console.log('FAILURE')
          that.errorMsg = response.data.login.message
          that.not_valid_login = true;
        }

      })
      .catch(function (error) {
        that.log('catch Function of AJAX-Called... error()');
        that.log(error);
        that.apiResponse = error;
        EventBus.$emit('show_error_lightbox');
      });
    });
  },
  mounted(){
    console.log('get localStorage...')
    let storedUserName = localStorage.userName;
    let storedUserPw = localStorage.userPw;
    if(storedUserName != '' || storedUserName != null || storedUserName != undefined){
      this.backend_login_mail = storedUserName
    } 
    if(storedUserPw != '' || storedUserPw != null || storedUserPw != undefined){
      this.backend_login_password = storedUserPw
    } 
  },
  watch:{
    date: function(newValue, oldValue) {
        if(newValue != oldValue) {
          const today = new Date()
          const tomorrow = new Date(today)
          tomorrow.setDate(tomorrow.getDate() + 1)
          var pickedDate = this.date
          if(pickedDate >= today || pickedDate.toDateString() == today.toDateString()){
          this.date = newValue
          this.isValid = true;
          }else{
            this.isValid = false;
          }
        }
      },
     backend_login_mail(){
       this.validateForm()
     },
     backend_login_password(){
       this.validateForm()
     }
      
  },
  components: {
    'submit-button': SubmitButton
  },
  methods: {
    validateForm(){
      if(this.backend_login_mail != '' && this.backend_login_password != ''){
        this.isValid = true
      }else{
        this.isValid = false;
      }
    },
    submit: function() {
        this.log('Appointment01.vue: Emitting Event using Submit: ' + this.event_name);
        EventBus.$emit(""+this.event_name);
    },
    log: function (obj) {
      if(this.debug) {
        this.log(obj)
      }
    },    
  }
}
</script>

<style>
 
  .fw-600{
    font-weight: 600;
  }
  .appointment-button-holder{
    display: block;
  }
  @media(min-width: 1440px){
    .appointment-button-holder{
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }
    .appointment-button-holder div{
      margin-bottom: 0;
    }

  }

</style>