<template>
    <div class="mb1" style="font-size: .85rem;">
        <div class="flex py2 px3" style="gap: 2rem;">
            <p class="col-4 bold">Datum:</p>
            <p class="col-8">{{formattedTestDay}}</p>
        </div>
        <div class="flex py2 px3 bg-lgray" style="gap: 2rem;">
            <p class="col-4 bold">Zeit:</p>
            <p class="col-8">{{aD.time}}</p>
        </div>
        <div class="flex py2 px3" style="gap: 2rem;">
            <p class="col-4 bold">Name:</p>
            <p class="col-8">{{aD.customer_name}}</p>
        </div>
        <div class="flex py2 px3 bg-lgray" style="gap: 2rem;">
            <p class="col-4 bold">Geburtstag:</p>
            <p class="col-8">{{formattedBirthday}}</p>
        </div>
    </div>
</template>

<script>

export default {
    name: 'UserInfo',
    props: {
      aD: Object
    },
    data() {
      return {
        debug: false,
        details: false,
        style_class: '',
      }
    },
    computed: {
        formattedTestDay: function(){
            let dArr = this.aD.date.split("-");  // ex input "2010-01-18"
            return dArr[2]+ "." +dArr[1]+ "." +dArr[0].substring(2); //ex out: "18/01/10"
        },
        formattedBirthday: function(){
            let dArr = this.aD.customer_birthday.split("-");  // ex input "2010-01-18"
            return dArr[2]+ "." +dArr[1]+ "." +dArr[0].substring(2); //ex out: "18/01/10"
        }
    }
}
</script>

<style scoped>
.bg-lgray{
  background-color: #f4f4f4;
}
</style>