<template>
    <div class="version-number">{{versionNumber}}</div>
</template>

<script>

export default {
    name: 'VersionNumber',
    props: {
      versionNumber: String
    },
}

</script>

<style scoped>
  .version-number{
    position: fixed; 
    bottom: 1rem;
    right: 1rem;
    z-index: 9999999;
    pointer-events: none;
  }
</style>