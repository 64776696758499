<template>
    <div class="lightbox-wrapper">
        <div class="lightbox mx-auto relative col-12 border-box bg-white">
                <div class="close-btn white" @click="closeLightbox()">
                    <img style="width: 1.5rem" src="./assets/cross_icon.svg" />
                </div>
                <div class="p3 mb2" style="background-color: darkred;">
                <h2 class="white">Fehler</h2>
                </div>
                <div class="p4 mx-auto max-width-4 bg-white">
                    <div class="flex justify-center mb3">
                    <!-- <img src="./assets/ajax-loader.gif"> -->
                    </div>
                <h3 class="center mb3">Die Datenübertragung ist leider gescheitert. Achten Sie auf eine gute Internetverbindung und versuchen Sie es ggf. erneut.</h3>
                <div class="flex justify-center">
                    <a class="btn std white"  @click="closeLightbox()">O.K.</a>
                </div>
                </div>
        </div>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";

export default {
  name: 'LoadingLightbox',
  methods: {
    closeLightbox() {
      EventBus.$emit("close_error_lightbox");
    },
  }
}
</script>

<style scoped>
    .lightbox-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 99999;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        align-content: center;
        min-height: 100vh;
        width: 100%;
    }
    .close-btn{
        position: absolute; 
        top: 2rem;
        right: 2rem;
        transform: rotate(45deg);
        cursor: pointer;
    }
</style>