<template>
  <div class="lightbox-wrapper">
    <div
      class="lightbox mx-auto bg-white drop-shadow relative col-12 border-box"
    >
    <div class="close-btn white" @click="closeLightbox()">
        <img style="width: 1.5rem" src="./assets/cross_icon.svg" />
      </div>
      <div class="p3 mb2" style="background-color: darkred">
        <h2 class="white">Filter-Optionen</h2>
      </div>
       <h2 class="center mx-auto my4 mb2 px3">Wählen Sie aus, nach welchem Buchstaben Sie filtern möchten:</h2>
      <div class="flex flex-wrap justify-center my4 px4" style="gap: 1.5rem">
          <p v-for="letter in letterArray" :key="letter" class="h4 pointer white p2 bg-darkred" :class="{'active-letter' : activeLetter == letter}" style="border-radius: 6px;" @click="setFilter(letter)">{{letter}}</p>
          <p v-if="activeLetter != ''" class="h4 pointer white p2 bg-darkred" style="border-radius: 6px;" @click="setFilter('')">Filter zurücksetzen</p>

      </div>
      <div class="flex justify-center">
        <a class="underline red pointer h4 mt4" @click="closeLightbox()">
          Zurück zur Übersicht
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import { EventBus } from "@/event-bus";

export default {
  name: "FilterLightbox",
  props: {
      activeLetter: String
  },
  data() {
      return {
          letterArray: [
              'A',
              'B',
              'C',
              'D',
              'E',
              'F',
              'G',
              'H',
              'I',
              'J',
              'K',
              'L',
              'M',
              'N',
              'O',
              'P',
              'Q',
              'R',
              'S',
              'T',
              'U',
              'V',
              'W',
              'X',
              'Y',
              'Z',
          ]
      }
  },
  created(){
      EventBus.$on('reset_filter', () => {
          this.setFilter('')
      })
  },
  methods: {
    closeLightbox() {
      EventBus.$emit("dataRefresh");
      EventBus.$emit("close_filter_lightbox");
    },
    setFilter(letter){
        console.log(letter)
        EventBus.$emit('setActiveLetter', {
            'letter': letter
        })
        this.closeLightbox();
    }
  }
};
</script>

<style scoped>
.lightbox-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-content: center;
  min-height: 100vh;
  width: 100%;
}
.close-btn {
  position: absolute;
  top: 2rem;
  right: 2rem;
  transform: rotate(45deg);
  cursor: pointer;
}
.active-letter{
    outline: 2px solid red;
    outline-offset: 3px;
    background-color: red;
}
</style>