<template>
    <div class="">
            <h2 class="p-big mb2" style="line-height: 1.3">
                Einwilligungserklärung über die Verarbeitung von personenbezogenen Daten:
              </h2>
              <p class="mb3">
                {{texts.FORM_INTRO}}
              </p>

              <!-- $4a -->
              <label v-show="aD.payment == 'Kostenfrei' || aD.payment == 'Ermaessigt'" class="dsgvo-container">
                  <input type="checkbox" v-model="check1" @click="check1 = !check1" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check1">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                  {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1}}
              </label>

              <!-- Datenschutz -->
              <label class="dsgvo-container">
                  <input type="checkbox" v-model="check2" @click="check2 = !check2" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check2">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                    {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2}}
              </label>

              <!-- Übermittlung -->
              <label class="dsgvo-container">
                  <input type="checkbox" v-model="check3" @click="check3 = !check3" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check3">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                    {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3}}
              </label>

              <!-- Hinweise -->
              <label v-show="aD.payment == 'Kostenfrei' || aD.payment == 'Ermaessigt'" class="dsgvo-container">
                  <input type="checkbox" v-model="check4" @click="check4 = !check4" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check4">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                    {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4}}
              </label>

              <!-- Einwilliging Verarbeitung personenbezogener Daten -->
              <label v-show="aD.payment == 'Kostenfrei' || aD.payment == 'Ermaessigt'" class="dsgvo-container">
                  <input type="checkbox" v-model="check5" @click="check5 = !check5" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check5">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                    {{texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5}}
              </label>

              <!-- Zusätzliche  Checkbox für Ermäßigung oder Kostenlos -->
              <label  class="dsgvo-container" v-show="aD.payment == 'Kostenfrei' || aD.payment == 'Ermaessigt'">
                  <input type="checkbox" v-model="check6" @click="check6 = !check6" @change="checkValid">
                  <span class="dsgvo-check">
                      <span class="dsgvo-tick" v-if="check6">
                        <svg width="15px" height="12px" viewBox="0 0 15 12" version="1.1">
                          <g id="Screens" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                            <g id="Styles_UI" transform="translate(-430.000000, -1562.000000)" fill="red">
                              <polygon id="Path" points="432.452132 1565.81544 435.616503 1569.02535 442.547868 1562 445 1564.48736 438.074306 1571.51264 435.616503 1574 430 1568.3028"></polygon>
                            </g>
                          </g>
                        </svg>

                    </span>
                  </span>
                 {{aD.payment == 'Kostenfrei' ? texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7 : ''}}{{aD.payment == 'Ermaessigt' ? texts.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6 : ''}} *
              </label>

              <p class="my2">* Pflichtfelder</p>
              <button v-if="!check1 || !check2 || !check3 || !check4 || !check5 || !check6" class="btn std white" @click="selectAll()" style="background-color: red; padding: .5rem 1rem">Alles ankreuzen</button>
    </div>
</template>

<script>

import { EventBus } from "@/event-bus.js";

export default {
  name: 'CheckBoxes',
  props: {
    aD: Object
  },
  data() {
    return {
        check1: false,
        check2: false,
        check3: false,
        check4: false,
        check5: false,
        check6: false,
        isValid: false,
        texts: {
            FORM_INTRO: process.env.VUE_APP_FORM_CONTINUATION_INTRO,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_1,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_2,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_3,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_4,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_5,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_6,
            VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7: process.env.VUE_APP_FORM_CONTINUATION_CHECKBOXES_TEXT_7,
            VUE_APP_FORM_CONTINUATION_VALID_DOCUMENT: process.env.VUE_APP_FORM_CONTINUATION_VALID_DOCUMENT,
      }
    }
  },
  created() {
  },
    beforeDestroy() {
        EventBus.$off('broadcast_sketchState');
    },
    methods: {
        checkValid(){
        if(this.aD.payment == 'Kostenfrei' && this.aD.payment == 'Ermaessigt'){
          if (
            this.check1 == true &&
            this.check2 == true &&
            this.check3 == true &&
            this.check4 == true &&
            this.check5 == true &&
            this.check6 == true
          ){
            this.isValid = true
          }else{
            this.isValid = false
          }
        }else{
          if (
            this.check2 == true &&
            this.check3 == true
          ){
            this.isValid = true
          }else{
            this.isValid = false
          }
        }
        EventBus.$emit('sendBoxData', {
            'checkBoxesValid': this.isValid
        })
      },
      selectAll(){
        this.check2 = true;
        this.check3 = true;
         if(this.aD.payment == 'Kostenfrei' || this.aD.payment == 'Ermaessigt'){
          this.check1 = true;
          this.check4 = true;
          this.check5 = true;
          this.check6 = true;
        }
        this.checkValid();
      }
    }
}
</script>

<style scoped>
/* The container */
.dsgvo-container {
  display: block;
  position: relative;
  padding-left: 48px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 13px;
  line-height: 17px;
  font-weight: 300;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.dsgvo-container input[type=checkbox] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 20px;
  width: 20px;
}

/* Create a custom checkbox */
.dsgvo-check {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 24px;
  width: 24px;
  border: 3px solid #06c;
  box-sizing: border-box;
}

/* On mouse-over, add a grey background color */
/* .checkbox-container:hover input[type=radio] ~ .checkmark {
  background-color: #ccc;
} */

/* When the checkbox is checked, add a blue background */
/* .checkbox-container input[type=radio]:checked ~ .checkmark {
  background-color: #2196F3;
} */

/* Create the checkmark/indicator (hidden when not checked) */
.dsgvo-check::before {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.dsgvo-container input[type=checkbox]:checked ~ .dsgvo-check::before {
  display: block;
}

.dsgvo-tick{
  position: absolute; 
  top: 2px;
  left: 2px;
}
.dsgvo-tick svg{
  width:14px;
}
</style>