import Vue from 'vue'
import App from './App.vue'
import 'jquery'
import '@fortawesome/fontawesome-free'
import VueCurrencyInput from 'vue-currency-input'
import VCalendar from 'v-calendar';
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/dist/vue-tel-input.css'
const pluginOptions = {
  /* see config reference */
  globalOptions: { currency: 'EUR' }
}
Vue.use(VueCurrencyInput, pluginOptions );
Vue.use(VCalendar, {
  componentPrefix: 'v',  // Use <vc-calendar /> instead of <v-calendar />
               // ...other defaults           
});
Vue.use(VueTelInput);


const cssThemes_Default = () => import('@/css/index.css')
const cssThemes_Addons = () => import('@/css/onboardingaddons.css')
const cssThemes_DevTheme = () => import('@/css/dev.css')


window.$ = window.jQuery = require('jquery');

switch (window.$("#onboardingapp").attr("data-source")) {
  // Used in Dev-Stuff...
  case 'localhost-dev': {
    cssThemes_Default();
    cssThemes_Addons();
    cssThemes_DevTheme();
    break
  }
  default: {
    cssThemes_Default();
    cssThemes_Addons();
   break
  }
  
}

if(process.env.VUE_APP_DIFFERENT_CSS != undefined) {
  if(process.env.VUE_APP_DIFFERENT_CSS == "true") {
    var element = document.createElement("link");
    element.setAttribute("rel", "stylesheet");
    element.setAttribute("type", "text/css");
    element.setAttribute("href", process.env.VUE_APP_DIFFERENT_CSS_PATH );
    document.getElementsByTagName("head")[0].appendChild(element);
  }
}
  

new Vue({
  el: "#onboardingapp",

  render: h =>
    h(App, {
      props: {
        source: ""+ window.$("#onboardingapp").attr("data-source"),
        counterpart: "" + window.$("#onboardingapp").attr("data-counterpart")
      },
      
    }),
    
});









