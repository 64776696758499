<template>
    <div class="lightbox-wrapper">
        <div class="lightbox mx-auto relative col-12 border-box bg-white">
                <div class="p3 mb2" style="background-color: darkred;">
                <h2 class="white">Please Wait...</h2>
                </div>
                <div class="p4 mx-auto max-width-4 bg-white">
                    <div class="flex justify-center mb3">
                    <img src="./assets/ajax-loader.gif">
                    </div>
                <h3 class="center">Daten werden übermittelt. Bitte warten...</h3>
                </div>
        </div>
    </div>
</template>

<script>



export default {
  name: 'ErrorLightbox',
}
</script>

<style scoped>
    .lightbox-wrapper{
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9999;
        background-color: rgba(0,0,0,0.5);
        display: flex;
        align-content: center;
        min-height: 100vh;
        width: 100%;
    }
    .close-btn{
        position: absolute; 
        top: 2rem;
        right: 2rem;
        transform: rotate(45deg);
        cursor: pointer;
    }
</style>