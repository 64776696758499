import { render, staticRenderFns } from "./VersionNumber.vue?vue&type=template&id=03d8d079&scoped=true&"
import script from "./VersionNumber.vue?vue&type=script&lang=js&"
export * from "./VersionNumber.vue?vue&type=script&lang=js&"
import style0 from "./VersionNumber.vue?vue&type=style&index=0&id=03d8d079&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03d8d079",
  null
  
)

export default component.exports